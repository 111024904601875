<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pay_manage.payment_log') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                v-model="search.org_id"
                :options="organizationList"
                id="org_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro.component')"
                label-for="component_id"
                >
                <b-form-select
                    plain
                    id="component_id"
                    :options="componentList"
                    v-model="search.component_id"
                    placeholder=""
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.application_type')"
              label-for="application_type"
            >
              <b-form-select
                plain
                v-model="search.application_type_id"
                :options="applicationTypeList"
                id="application_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col  xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irrigation_config.payment_type')"
              label-for="payment_type"
            >
              <b-form-select
                plain
                v-model="search.payment_type_id"
                :options="paymentTypeList"
                id="payment_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pay_manage.payment_log_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button variant="primary" v-b-modal.modal-1 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ data.item.organization }}
                    </template>
                    <template v-slot:cell(application_type)="data">
                      {{ data.item.application_type }}
                    </template>
                    <template v-slot:cell(payment_by)="data">
                      {{ data.item.user_name }}
                    </template>
                    <template v-slot:cell(payment_by_bn)="data">
                      {{ data.item.user_name_bn }}
                    </template>
                    <template v-slot:cell(transection_date)="data">
                      {{ $d(new Date(data.item.created_at)) }}
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId" />
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Form from './Form'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { masterPaymentList, masterPaymentToggleStatus, orgWizeComponentList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV: Form
  },
  data () {
    return {
      search: {
        org_id: 0,
        component_id: 0,
        application_type_id: 0,
        payment_type_id: 0,
        participation_category_id: 0
      },
      id: 0,
      organizationList: [],
      allUserList: [],
      compData: [],
      applicationTypeList: [
        { value: 1, text: 'Scheme' },
        { value: 2, text: 'Pump Operation' },
        { value: 3, text: 'Smart Card' },
        { value: 4, text: 'Water Testing' }
      ],
      paymentTypeList: [
        { value: 1, text: 'Form Fee' },
        { value: 2, text: 'Participation Fee' },
        { value: 3, text: 'Security Money' },
        { value: 4, text: 'Resunk Fee' },
        { value: 5, text: 'New' },
        { value: 6, text: 'Reissue' },
        { value: 7, text: 'Drinking Water' },
        { value: 8, text: 'Irrigation Water' },
        { value: 9, text: 'Industrial Water' },
        { value: 10, text: 'Application Fee' },
        { value: 11, text: 'Renew Fee' },
        { value: 12, text: 'Security Money' }
      ],
      participationCategoryList: [
        { value: 1, text: 'Deep Tubewell' },
        { value: 2, text: 'Irrigation Drain' },
        { value: 3, text: 'Electrification' },
        { value: 4, text: 'Solar' }
      ]
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('irrigation_config.payment_entry') : this.$t('irrigation_config.payment') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('irrigation_config.organization'), class: 'text-left' },
        { label: this.$t('pay_manage.service'), class: 'text-left' },
        { label: this.$t('pay_manage.payment_by'), class: 'text-left' },
        { label: this.$t('pay_manage.bill_no'), class: 'text-left' },
        { label: this.$t('pay_manage.transection_no'), class: 'text-left' },
        { label: this.$t('pay_manage.transection_date'), class: 'text-left' }
      ]

      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'application_type_bn' },
          { key: 'payment_by_bn' },
          { key: 'bill_no' },
          { key: 'transection_no' },
          { key: 'transection_date' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'application_type' },
          { key: 'payment_by' },
          { key: 'bill_no' },
          { key: 'transection_no' },
          { key: 'transection_date' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    componentList: function () {
        const listObject = this.compData
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
        return tmpList
    },
    SchemeTypeList: function () {
      const schemeTypes = this.$store.state.IrriConfig.schemeTypes
      const tmpList = schemeTypes.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.payment_type_name_bn }
        } else {
          return { value: item.id, text: item.payment_type_name }
        }
      })
      return tmpList
    }
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.getComponentList(newVal)
        }
      },
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.getAllUserList()
    if (this.allUserList.length > 0) {
        this.loadData()
    }
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, masterPaymentToggleStatus, item)
    },
    async getComponentList (orgId) {
      const orgSearch = {
        org_id: orgId
      }
      await RestApi.getData(commonServiceBaseUrl, orgWizeComponentList, orgSearch).then(response => {
        if (response.success) {
          this.compData = response.data
        }
      }, error => {
        if (error) {
        //
        }
      })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, masterPaymentList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getAllUserList () {
      RestApi.getData(authServiceBaseUrl, 'user/getAllUser').then(response => {
        this.allUserList = response.data
      })
    },
    dataList (data) {
      const orgList = this.$store.state.orgList
      const applicationTypeList = this.$store.state.commonObj.paymentApplicationTypeList
      // const schemeBADCPaymentTypeList = this.$store.state.commonObj.paymentSchemeBADCPaymentTypeList
      // const schemeBMDAPaymentTypeList = this.$store.state.commonObj.paymentSchemeBMDAPaymentTypeList
      // const pumpOperationPaymentTypeList = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
      // const smartCardPaymentTypeList = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
      // const waterTestingPaymentTypeList = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
      let tmpData = {}
      // let paymentTypeData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(org => org.value === item.org_id)
        const orgData = { org_name_bn: tmpData.text_bn, org_name: tmpData.text_en }
        tmpData = applicationTypeList.find(applicationType => applicationType.id === item.application_type_id)
        const appData = { application_type_bn: tmpData.name_bn, application_type: tmpData.name }
        tmpData = this.participationCategoryList.find(participationItem => participationItem.value === item.participation_category_id)
        const participationCategoryData = { participation_category: tmpData ? tmpData.text : '' }
        const userObject = this.allUserList.find(user => user.user_id === item.created_by)
        const userData = {
          user_name: userObject.name,
          user_name_bn: userObject.name_bn
        }
        return Object.assign({}, item, orgData, appData, participationCategoryData, userData)
      })
      return listData
    },
    getOrganizationlist () {
      this.organizationList = this.$store.state.orgList
    }
  }
}
</script>
