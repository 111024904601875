// payment Log
export const masterPaymentList = 'master-payment/list'
export const masterPaymentUpdate = 'master-payment/update'
export const masterPaymentToggleStatus = 'master-payment/toggle'
export const masterPaymentStore = 'master-payment/store'
// Service Entry
export const orgWizeComponentList = 'component/org-wise-component'
export const serviceList = 'payment-service-entry/list'
export const serviceStore = 'payment-service-entry/store'
export const serviceUpdate = 'payment-service-entry/update'
export const serviceToggleStatus = 'payment-service-entry/toggle-status'
export const serviceDestroy = 'payment-service-entry/destroy'
